/**
 * Here there are the javascript utils for handlebars
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

/* globals window */

(function ( $, _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;


	// HANDLEBARS RENDERER
	// ===================

	var handlebarsTemplates = {
		// used to cache the compiled templates
		compiled: $.extend( {}, Handlebars.templates || {} ),

		getTemplate: function ( id ) {
			return String( $( '#template-' + id ).html() ).trim() || 'Missing Template: ' + id;
		},

		registerPartial: function ( id ) {
			Handlebars.registerPartial( id, this.compiled[id] || this.getTemplate( id ) );
		},

		render: function ( type, data ) {
			var result = null;

			if ( !type ) {
				throw new Error( 'handlebars-renderer.js - called with undefined template' );
			}

			if ( root.DEBUG ) {
				result = '<div class="alert alert-danger">' +
						'<strong>HANDLEBARS Compilation Runtime Not Available:</strong><br>' +
						'Please provide a pre-compiled template for ' + type +
						'</div>';
			}

			if ( !this.compiled[type] && Handlebars.compile ) {
				this.compiled[type] = Handlebars.compile( this.getTemplate( type ) );
			}

			if ( _.isFunction( this.compiled[type] ) ) {
				result = this.compiled[type]( data );
			}

			return result;
		}
	};

	root.handlebarsTemplates = handlebarsTemplates;


	// HANDLEBARS HELPERS
	// ==================

	/**
	 *
	 */
	Handlebars.registerHelper( 'calculate', function ( lvalue, operator, rvalue, options ) {
		var result,
			operators = {
				'+': function ( l, r ) { return (+l) + (+r); },
				'-': function ( l, r ) { return (+l) - (+r); },
				'*': function ( l, r ) { return (+l) * (+r); },
				'/': function ( l, r ) { return (+l) / (+r); },
				'%': function ( l, r ) { return (+l) % (+r); }
			};

		if ( !operators[operator] ) {
			throw new Error( 'Handlebars Helper "calculate": undefined operator ' + operator );
		}

		result = operators[operator]( lvalue, rvalue );

		if ( options && options.fn ) {
			if ( result !== null ) {
				result = options.fn( this );
			} else {
				result = options.inverse( this );
			}
		}

		return result;
	} );

	
	Handlebars.registerHelper('toLowerCase', function(str) {
		return str.toLowerCase();
	});
	
	
	
	
	Handlebars.registerHelper( 'findSizechartCategories', function ( aCategories ) {
		/*var aCateoriesParsed = [],
				aKeys = [],
				sValue = '';
		
		if( aCategories == '' ) {
			$.each( PRODUCT_CATEGORIES, function( keys ){
				aKeys.push( keys );
				if(sValue != ''){
					sValue +=',';
				}
				sValue +=keys;
				
			});
		}
		else{
			aKeys = _.keys( aCategories );
		}
		
		//aCateoriesParsed = _.intersection( window.ZG_SIZECHART_CATEGORIES, aKeys);
		console.log(sValue);
		//return aCateoriesParsed[0];
		//return sValue;*/
		//return product id
		return window.PRODUCT_ID;
		
	});
	/**
	 *
	 */
	Handlebars.registerHelper( 'promoBanner', function ( block, options ) {
		if (typeof window.PROMO_BANNER[0] === "undefined" || !window.PROMO_BANNER || (window.PROMO_BANNER[0]['block']!==block && window.PROMO_BANNER[0]['block']!=='both' )) {
			return options.inverse();
		}
		return _.reduce( window.PROMO_BANNER, function ( buffer, element ) {
			// pid has to be a string
			var text =  element.text;

			return ( text ) ?	buffer + options.fn( element ) : buffer;

		}, '' );
	});
				
	Handlebars.registerHelper( 'generateDiscount', function ( price, options ) {
		var calculatedDiscount=0
		//console.log(price);
		if(price.sell !== null && price.to_discount !==null && price.to_discount !==0){
			calculatedDiscount = ( 1 - ( price.sell / price.to_discount ) ) * 100;
			price.calculatedDiscount=Math.round( calculatedDiscount );
		}
		if ( calculatedDiscount !== 0 ) {
			return options.fn( price );
		}
		return options.inverse();

	} );
	
	Handlebars.registerHelper( 'generateCmsStickers', function ( pid, categories,promotions, options ) {

		var categoryKeys  = _.keys( categories ),
			promotionKeys = ( promotions && promotions.applied ) ? _.keys( promotions.applied ) : [],
			isProduct = false,
			isAssignedToPromotion = false,
			isAssignedToCategories = false;

		if ( !window.CMS_STICKERS ) {
			return options.inverse();
		}
		return _.reduce( window.CMS_STICKERS, function ( buffer, element ) {
			// pid has to be a string
			isProduct =  _.contains( element.products, '' + pid );
			isAssignedToCategories = _.intersection( categoryKeys, element.categories ).length;
			isAssignedToPromotion = !!_.intersection( promotionKeys, element.promotions ).length;

			return ( isProduct || isAssignedToCategories || isAssignedToPromotion ) ?
			buffer + options.fn( element ) : buffer;

		}, '' );

	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'compare', function ( lvalue, operator, rvalue, options ) {
		var result,
			operators = {
				'&&':     function ( l, r ) { return l && r; },
				'||':     function ( l, r ) { return l || r; },
				'==':     function ( l, r ) { return l == r; },
				'===':    function ( l, r ) { return l === r; },
				'!=':     function ( l, r ) { return l != r; },
				'!==':    function ( l, r ) { return l !== r; },
				'<':      function ( l, r ) { return l < r; },
				'>':      function ( l, r ) { return l > r; },
				'<=':     function ( l, r ) { return l <= r; },
				'>=':     function ( l, r ) { return l >= r; },
				'typeof': function ( l, r ) { return typeof l == r; }
			};

		if ( !operators[operator] ) {
			throw new Error( 'Handlebars Helper "compare": undefined operator ' + operator );
		}

		result = operators[operator]( lvalue, rvalue );

		if ( options && options.fn ) {
			if ( result ) {
				result = options.fn( this );
			} else {
				result = options.inverse( this );
			}
		}

		return result;
	} );

	/**
	 * Replace url http with https
	 *
	 */
	Handlebars.registerHelper( 'convertToHttps', function ( url ) {
		return url.replace( 'http://', 'https://' );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'count', function ( variable, options ) {
		var prop, result = 0;

		if ( _.isArray( variable ) ) {

			result = variable.length;

		} else if ( _.isObject( variable ) ) {

			for ( prop in variable ) {
				if ( variable.hasOwnProperty( prop ) ) {
					result++;
				}
			}

		} else if ( !_.isNull( variable ) && !_.isUndefined( variable ) ) {

			result = 1;
		}

		if ( options && options.fn ) {
			if ( result ) {
				result = options.fn( result );
			} else {
				result = options.inverse( this );
			}
		}

		return result;
	} );

	/**
	 * Prints information about the current variable / context to the console
	 *
	 */
	Handlebars.registerHelper( 'debug', function ( value, options ) {
		if ( root.DEBUG ) { // only print debug info if the page is in DEBUG mode
			console.log( '/ -------------' );
			console.log( 'Context:', this );

			if ( value && options ) {
				console.log( '----' );
				console.log( 'Value:', value );
			}
		}
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'encodeURIComponent', function ( component ) {
		return encodeURIComponent( component );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'getCurrentUrl', function () {
		return window.location.href;
	} );

	Handlebars.registerHelper( 'getCurrentUrlEncoded', function () {
		return encodeURIComponent( window.location.href );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'getElement', function ( context, item, options ) {
		var result;

		if ( context && context[item] ) {
			result = options.fn( context[item] );
		} else {
			result = options.inverse( this );
		}

		return result;
	} );
	Handlebars.registerHelper( 'getStar', function ( n ) {
		var result='';

		for(var i=0;i< n;i++)
			result+='<i class="fa fa-star" aria-hidden="true"></i>';

		return new Handlebars.SafeString(result);
	} );

	/**
	 * Get a js variable from the template.
	 *
	 * usage:
	 *     {{getJsVar "varName" }}
	 *     {{getJsVar "obj.path.to.var" }}
	 */
	Handlebars.registerHelper( 'getJsVar', function ( variable, options ) {
		var i,
			prop = variable.split( '.' ),
			obj  = root;

		for ( i = 0; i < prop.length && obj; i++ ) {
			if ( obj[prop[i]] ) {
				obj = obj[prop[i]];
			} else {
				obj = false;
			}
		}

		if ( options && options.fn ) {
			if ( obj ) {
				obj = options.fn( obj );
			} else {
				obj = options.inverse( this );
			}
		}

		return obj;
	} );

	/**
	 * Get all the categories
	 */
	Handlebars.registerHelper( 'getSeoCategories', function ( categories ) {
		var seoCat = [],
			index;

		for ( index in categories ) {
			if ( categories.hasOwnProperty( index ) ) {
				seoCat.push( categories[index].name );
			}
		}

		return seoCat.join( '|' );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'makeUrl', function ( action, manager, module ) {
		var components = {};

		if ( action ) {
			components.action = action;
		}

		if ( manager ) {
			components.manager = manager;
		}

		if ( module ) {
			components.module = module;
		}

		return root.makeUrl( components );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'nl2br', function ( string ) {
		return new Handlebars.SafeString( root.nl2br( string ) );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'preloadImage', function ( img ) {
		if ( _.isString( img ) ) {
			if ( root.IMG_VERSION ) {
				img += '?v=' + root.IMG_VERSION;
			}

			(new Image()).src = img;
		}
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'renderPrice', function ( value, avoidCurrency ) {
		var avoid = _.isBoolean( avoidCurrency ) ? avoidCurrency : false;

		return new Handlebars.SafeString( root.renderPrice( value, avoid ) );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'sortByName', function ( values, property, pattern, options ) {
		var array, result, i;

		if ( !options ) {
			if ( pattern ) {
				options  = pattern;
				pattern = null;
			} else {
				options  = property;
				property = null;
			}
		}

		array = root.zgSortObjectByProp( values, property, pattern );

		if ( array.length ) {
			result = '';

			for ( i = 0; i < array.length; i++ ) {
				result += options.fn( array[i], { data: { index: i } } );
			}
		} else {
			result = options.inverse( this );
		}

		return result;
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'split', function ( value, separator ) {
		return String( value ).split( separator || ';' );
	} );



	/**
	 *
	 */
	Handlebars.registerHelper( 'toFixed', function ( number, fixedTo ) {
		// Only allow integers as parameter
		var fixedDecimals = ( typeof fixedTo === 'number' && isFinite( fixedTo ) && fixedTo % 1 === 0 ) ? fixedTo : 2;

		if ( typeof number === 'string' ) {
			number = number.replace( /,/g, '' );
		}

		return Number( number ).toFixed( fixedDecimals );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'translate', function ( translation ) {
		return JS_TRANSLATIONS[translation] || '>>- ' + translation + ' -<<';
	} );

	Handlebars.registerHelper( 'voucherCustomValues', function ( custom_availables, options ) {
		var result = "";
		for ( var i = 0; i < custom_availables.length; i++ ) {
			result += options.fn( custom_availables[i]);
		}
		return result;
	});

	Handlebars.registerHelper('concat', function () {
		return Array.prototype.slice.call(arguments, 0, -1).join('');
	});

	// HANDLEBARS PARTIALS
	// ===================

	//
	handlebarsTemplates.registerPartial( 'gallery-item' );

	//
	handlebarsTemplates.registerPartial( 'image' );

	//
	handlebarsTemplates.registerPartial( 'product-availability' );
	handlebarsTemplates.registerPartial( 'product-options' );
	handlebarsTemplates.registerPartial( 'product-price' );
	handlebarsTemplates.registerPartial( 'product-shopByLook' );

	//
	handlebarsTemplates.registerPartial( 'social-share' );

	//
	handlebarsTemplates.registerPartial( 'storelocator-missing-geolocation-status' );


	// HANDLEBARS AUTOMATIC RENDERING
	// ==============================


	$( function () {
		$( '[data-zg-role="render-handlebars"]' ).each( function () {
			var $this = $( this ),
				data = $this.data();

			$this.html( root.handlebarsTemplates.render( data.template, data.content || {} ) );
		} );
	} );

}.call( this, jQuery, _ ));
